import React from "react"

import Layout from "../../components/layout"
import Php from "../../components/screens/Skill/Php"
import { Helmet } from "react-helmet"

const PhpMysql = () => (
  <Layout pageInfo={{ pageName: "Php Mysql" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Php Mysql</title>
        <meta name="description" content="Php Mysql" />
    </Helmet>
    <Php />
  </Layout>
)

export default PhpMysql
