import React, { Component } from "react"
import { Image } from "react-bootstrap"
import { OutboundLink } from 'gatsby-plugin-gtag'

class Php extends Component {
  render() {
    return (
      <div className="wrapper-skill">
        <Image
          alt="900x500"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
          data-src={require("../../../../images/skill/php.jpg")}
          className="bannerSkill lazyload"
        />
        <div className="tab-skill">
          <OutboundLink to="/capabilities/php-mysql" activeClassName="active">Php/mysql</OutboundLink>
          <OutboundLink to="/capabilities/jquery-ajax" activeClassName="active">Jquery/Ajax</OutboundLink>
          <OutboundLink to="/capabilities/magento" activeClassName="active">Magento</OutboundLink>
          <OutboundLink to="/capabilities/joomla" activeClassName="active">Joomla</OutboundLink>
          <OutboundLink to="/capabilities/wordpress" activeClassName="active">WordPress</OutboundLink>
          <OutboundLink to="/capabilities/html-css" activeClassName="active">Css3/html5</OutboundLink>
          <OutboundLink to="/capabilities/pts-illus" activeClassName="active">Phptoshop/Illustrator</OutboundLink>
          <OutboundLink to="/capabilities/nginx" activeClassName="active">Apache/Nginx</OutboundLink>
          <OutboundLink to="/capabilities/varnish" activeClassName="active">Varnish</OutboundLink>
        </div>
        <div className="content-skill">
          <h1 className="title-page-skill">Php/ My sql</h1>
          <div>
            <div className="left-content-skill">
              <p>Since Open source PHP technology inception it's highly conjunction with MySQL. Based on that LAMP stack is most flavored open source development for quick and easy development. PHP MySQL is the best fit solution for ecommerce solution, web portal development, web application development. It helps to strengthen your business online presentence with quick turnaround time.</p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/capabilities-php-2.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill reverse">
              <p>We have highly skilled php mysql development team. We offer low cost and high performance PHP MySQL development solution for any business need. Our team of proficient developers believes in innovation and experimenting, while adhering to the client requirements. Our PHP web MySQL programming professionals aim to explore various facets of the domain.</p>
            </div>
            <div className="right-content-skill reverse">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/content-pts.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
          <br/>
          <div>
            <div className="left-content-skill">
              <p>We provides affordable, efficient and timely PHP Programming Services, in order to provide Professional Web Programming Services for both new and existing Dynamic Websites running on the PHP , Apache and MySQL combination becoming choice of masses for delivering dynamic web content.</p>
            </div>
            <div className="right-content-skill">
              <Image
                alt="900x500"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                data-src={require("../../../../images/skill/capabilities-php-3.jpg")}
                className="bannerSkill lazyload"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Php;
